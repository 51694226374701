import sanityClient from "@sanity/client";

export const config = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || "production",
  apiVersion: "2021-06-25",
  useCdn: process.env.NODE_ENV === "production",
};

/**
 * Sanity client.
 */
export const sanity = sanityClient(config);

/**
 * Authenticated Sanity client that can access draft documents.
 */
export const sanityAuthenticated = sanityClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_API_TOKEN,
});

/**
 * Returns an appropriate Sanity client based on whether if Preview mode is enabled.
 */
export const getSanity = (preview?: boolean) =>
  preview ? sanityAuthenticated : sanity;
