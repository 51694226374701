import { getSanity } from "lib/sanity/sanity.server";
import Fetch from "../../lib/fetch";

export default class CommonService extends Fetch {
  /**
   * Request Terms of Service
   */
  public async getTermsOfService(): Promise<ResTermsOfService> {
    try {
      return await this.get(
        `https://prod.lawfully-api.com/mobile/v1/legal-material?title=Terms%20of%20Service`,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Request policy page content
   */
  public async getPolicy({
    slug,
    preview = false,
  }: {
    slug:
      | "privacy-policy"
      | "terms-of-service"
      | "limited-scope-representation-agreement";
    preview?: boolean;
  }): Promise<{
    content: string;
    title: string;
    lastUpdateDate?: string;
  }> {
    try {
      const sanity = getSanity(preview);
      const data = await sanity.fetch(
        `
  *[_type == "policy" && slug.current == $slug] {
    _id,
    title,
    lastUpdateDate,
    content,
  }
      `,
        { slug },
      );
      // Normally, there should be only one resource matched for a given slug.
      // If a content editor accesses the preview of an already published resource,
      // there will be two matched resources for a given slug:
      //   - published resource
      //   - draft
      // The draft's ID starts with "drafts".
      const sorted = data.sort((a) => (a._id.startsWith("drafts") ? 1 : -1));
      const resource = {
        published: sorted[0],
        draft: sorted[1],
      };
      return resource.draft || resource.published;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  /**
   * Request Limited Scope Representation Agreement
   */
  public async getLimitedScopeRepresentationAgreement(): Promise<ResPrivacyPolicy> {
    try {
      return await this.get(
        `https://prod.lawfully-api.com/mobile/v1/legal-material?title=Limited%20Scope%20Representation%20Agreement`,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Request Lawfully stats
   */
  public async getLawfullyStats(): Promise<ResLawfullyStats> {
    try {
      return await this.get(
        "https://prod.lawfully-api.com/common/v1/lawfully-stat",
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Register Case Tracker Lite.
   */
  public async registerCaseTrackerLite({
    mobileNumber,
    email,
    caseNumber,
  }: ReqCTLRegister) {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/tracker-lite`,
        {
          mobileNumber,
          email,
          caseNumber,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Send Case Tracker app download link via SMS.
   */
  public async sendAppDownloadLink({ mobileCountry, mobileNumber }) {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/send-app-download-sms`,
        {
          mobileCountry,
          mobileNumber,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Create subscription activation discount
   */
  public async createSubscriptionDiscount() {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/mobile/v1/create-subscription-discount`,
        {},
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves app rating from Apple app store.
   */
  public async getAppstoreRating() {
    try {
      const res = await fetch("https://itunes.apple.com/lookup?id=1435063223");
      const json = await res.json();
      return json.results[0].averageUserRatingForCurrentVersion;
    } catch (e) {
      throw e;
    }
  }
}
