import React, { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { animated } from "@react-spring/web";
import { config } from "@react-spring/core";
import { useTransition } from "react-spring";
import CommonService from "domain/common/commonService";
import dialCodes from "lib/select-options/dialCodes";
import Select, { SelectOption } from "lib/components/Select";
import { useEvent } from "lib/contexts/eventContext";
import videoDownloadApp from "lib/assets/app-download-modal.mp4";
import { UIEvent } from "lib/enums";
import IconInfo from "lib/assets/info-toast.svg";
import IconClose from "lib/assets/close-toast.svg";
import IconCloseModal from "lib/assets/close-modal.svg";
import Modal, { ModalContent, ModalTitle } from "./Modal";
import TextField from "./TextField";
import Button from "./Button";

interface ModalDownloadAppProps {
  open: boolean;
  onSuccess: () => void;
  onClose: () => void;
  eventSubmit?: UIEvent;
}

const ModalDownloadApp: React.FC<ModalDownloadAppProps> = ({
  open,
  onSuccess,
  onClose,
  eventSubmit,
}) => {
  /**
   * Country codes.
   */
  const COUNTRY_CODE_OPTIONS = dialCodes as SelectOption[];
  const { track } = useEvent();
  const { control, handleSubmit, formState, errors } = useForm({
    defaultValues: {
      mobileCountry: "+1",
      mobileNumber: "",
    },
  });

  const handleOnVideoToggle = useCallback(() => {
    const video = document.getElementById("video-download-app");
    if (video instanceof HTMLVideoElement) {
      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, []);

  const handleOnSubmit = handleSubmit(
    async ({ mobileCountry, mobileNumber }) => {
      try {
        await new CommonService().sendAppDownloadLink({
          mobileCountry,
          mobileNumber,
        });
        onClose();
        onSuccess();
        eventSubmit && track(eventSubmit);
      } catch (e) {
        // noop
      }
    },
  );

  return (
    <Modal
      css={`
        &&& .modal-paper-root {
          max-width: 720px;
          width: 100%;
        }
      `}
      open={open}
      onClose={onClose}
    >
      <ModalContent>
        {/* close button */}
        <div
          css={`
            margin: 0 0 10px;
            text-align: right;
          `}
        >
          <button
            css={`
              margin: 0;
              padding: 0;
              border: none;
              background: none;
              cursor: pointer;
            `}
            onClick={onClose}
          >
            <IconCloseModal />
          </button>
        </div>
        <div
          css={`
            position: relative;
            border-radius: 20px;
            padding-bottom: ${(371.25 / 660) * 100}%;
            width: 100%;
            height: 0;
            background-color: #f1f3f5;
          `}
        >
          <video
            id="video-download-app"
            css={`
              border-radius: 20px;
              width: 100%;
            `}
            autoPlay
            muted
            onClick={handleOnVideoToggle}
            onEnded={handleOnVideoToggle}
          >
            <source src={videoDownloadApp} type="video/mp4" />
          </video>
        </div>
        <div
          css={`
            margin: 20px 0 20px;
            line-height: 24px;
            font-size: 16px;
          `}
        >
          Enter your phone number and we&apos;ll text you with a link to
          download Lawfully USCIS Case Tracker.
        </div>
        <form onSubmit={handleOnSubmit}>
          <div
            css={`
              margin-bottom: 20px;
              display: flex;
            `}
          >
            <div
              css={`
                margin-right: 10px;
                width: 105px;
              `}
            >
              <Controller
                control={control}
                rules={{ required: true }}
                name="mobileCountry"
                render={(props) => {
                  return (
                    <Select
                      {...props}
                      css={`
                        &&& .input-base {
                          padding-right: 0;
                        }
                      `}
                      options={COUNTRY_CODE_OPTIONS}
                      placeholder="+"
                      disabled={formState.isSubmitting}
                      error={!!errors?.mobileCountry}
                    />
                  );
                }}
              />
            </div>
            <div
              css={`
                margin-right: 10px;
                flex: 1;
              `}
            >
              <Controller
                control={control}
                rules={{ required: true }}
                name="mobileNumber"
                render={(props) => {
                  return (
                    <TextField
                      {...props}
                      type="tel"
                      placeholder="123-456-789"
                      fullWidth
                      disabled={formState.isSubmitting}
                      error={!!errors?.mobileNumber}
                    />
                  );
                }}
              />
            </div>
            <div
              css={`
                width: 150px;
              `}
            >
              <Button
                css={`
                  &&& {
                    height: 48px;
                    font-weight: 500;
                  }
                `}
                type="submit"
                disabled={formState.isSubmitting}
              >
                Send
              </Button>
            </div>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ModalDownloadApp;

export const useDownloadAppResultMessage = () => {
  const [isResultVisible, setIsResultVisible] = useState(false);
  const resultTransitions = useTransition(isResultVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  const handleOnResultClose = useCallback(() => {
    setIsResultVisible(false);
  }, []);

  const showResult = useCallback(() => {
    setIsResultVisible(true);
    setTimeout(() => setIsResultVisible(false), 3000);
  }, []);

  const SnackbarResult = useMemo(() => {
    const Comp = () => {
      return resultTransitions(
        (styles, item) =>
          !!item && (
            // eslint-disable-next-line
            // @ts-ignore
            <animated.div
              style={{
                ...styles,
                y: styles.opacity.to([0, 1], [20, 0]),
                position: "fixed",
                zIndex: 9999999999, // Cover Intercom launcher underneath the modal.
                left: 0,
                right: 0,
                bottom: 0,
                padding: "0 20px",
              }}
            >
              <div
                css={`
                  margin: 0 auto 20px;
                  border-radius: 10px;
                  padding: 20px 30px 20px 20px;
                  max-width: 1000px;
                  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
                  display: flex;
                  align-items: flex-start;
                  line-height: 24px;
                  font-size: 16px;
                  background-color: white;
                `}
              >
                <div
                  css={`
                    flex: 1;
                    display: flex;
                    align-items: flex-start;
                  `}
                >
                  <IconInfo />
                  <div
                    css={`
                      margin-left: 10px;
                      flex: 1;
                    `}
                  >
                    We have just sent a download link to Lawfully app to your
                    phone! Check your text message.
                  </div>
                </div>
                <div
                  css={`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <button
                    css={`
                      display: flex;
                      border: none;
                      background: none;
                      cursor: pointer;
                    `}
                    onClick={handleOnResultClose}
                  >
                    <IconClose
                      css={`
                        path {
                          fill: #999;
                        }
                      `}
                    />
                  </button>
                </div>
              </div>
            </animated.div>
          ),
      );
    };
    return Comp;
  }, [handleOnResultClose, resultTransitions]);

  return {
    showResult,
    SnackbarResult,
  };
};
